import React from "react";
import { Link } from "gatsby";
import { Box, Button, Grid, Typography } from "@mui/material";

import NotFoundImage from "../images/404.jpg";

import Container from "../components/container";
import PageWrapper from "../components/page-wrapper";
import { ROUTES } from "../lib/routes";
import { SECTION_SX } from "../styles/shared";

const browser = typeof window !== "undefined" && window;

export default function NotFoundPage() {
  return (
    browser && (
      <PageWrapper
        pageDescription="StatusVista page not found"
        pageTitle="Not found"
      >
        <Box component="section" sx={SECTION_SX}>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundImage: `url(${NotFoundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: 1,
                    width: "100%",
                    height: 300,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h1" fontWeight="fontWeightBold">
                  404
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="fontWeightBold"
                  sx={{ mb: 1 }}
                >
                  Page not found
                </Typography>
                <Typography fontSize="large" sx={{ mb: 4 }}>
                  You've stumbled upon a page that doesn't exist yet.
                </Typography>
                <Link to={ROUTES.HOME}>
                  <Button variant="outlined">Go home</Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </PageWrapper>
    )
  );
}
